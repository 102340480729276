import request from '@/utils/request'

/*
@keyword : manager-api-hosts
*/
export function fetchList(query) {
  return request({
    url: '/brokers',
    method: 'get',
    params: query
  })
}
export function fetchListClients(query) {
  return request({
    url: '/brokers/clients',
    method: 'get',
    params: query
  })
}

export function show(id) {
  return request({
    url: `brokers/view/`,
    method: 'get',
    params: { id }
  })
}

export function store(data) {
  return request({
    url: '/brokers/store',
    method: 'post',
    data
  })
}

export function edit(id) {
  return request({
    url: `brokers/edit/`,
    method: 'get',
    params: { id }
  })
}


export function update(data) {
  return request({
    url: '/brokers/update',
    method: 'post',
    data
  })
}


export function destroy(id) {
  return request({
    url: `brokers/${id}`,
    method: 'delete'
  })
}

export function passwordUpdate(data) {
  return request({
    url: '/brokers/updatepassword',
    method: 'post',
    data
  })
}

export function resettransactionpassword(data) {
  return request({
    url: '/brokers/resettransactionpassword',
    method: 'post',
    data
  })
}




