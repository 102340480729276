import request from '@/utils/request'

/*
@keyword : manager-api-hosts
*/
export function fetchList(query) {
  return request({
    url: 'markertwatch',
    method: 'get',
    params: query
  })
}

export function fetchClientList(query) {
  return request({
    url: 'clients/all',
    method: 'get',
    params: query
  })
}

export function importStock(query) {
  return request({
    url: 'stock/import',
    method: 'get',
    params: query
  })
}

export function fetchBrokersList(query) {
  return request({
    url: 'brokers/all',
    method: 'get',
    params: query
  })
}

export function store(data) {
  return request({
    url: '/order-place',
    method: 'post',
    data
  })
}

export function show(id) {
  return request({
    url: `markertwatch/${id}`,
    method: 'get',
    params: { id }
  })
}

// export function edit(id) {
//   return request({
//     url: `markertwatch/${id}`,
//     method: 'get'
//   })
// }

// export function update(id, data) {
//   return request({
//     url: `markertwatch/${id}`,
//     method: 'put',
//     data
//   })
// }

export function edit(id) {
  return request({
    url: `markertwatch/edit/`,
    method: 'get',
    params: { id }
  })
}

export function update(data) {
  return request({
    url: '/markertwatch/update',
    method: 'post',
    data
  })
}

export function destroy(id) {
  return request({
    url: `markertwatch/delete/`,
    method: 'POST',
    params: { id }
  })
}

export function addStock(data) {
  return request({
    url: `markertwatch/addstock`,
    method: 'post',
    data
  })
}
export function fetchStockList(query) {
  return request({
    url: 'allowed-script',
    method: 'get',
    params: query
  })
}

export function fetchAdminAllowedStockList(query) {
  return request({
    url: 'allowed-admin-script',
    method: 'get',
    params: query
  })
}


export function clientCloseActiveTrade(id) {
  return request({
    url: `clients/close-active-trade/`,
    method: 'get',
    params: { id }
  })
}


export function fetchMcxNseList(query) {
  return request({
    url: 'script-list',
    method: 'get',
    params: query
  })
}
export function favorite(query) {
  return request({
    url: 'favorite',
    method: 'get',
    params: query
  })
}
export function lock(data) {
  return request({
    url: 'markertwatch/block',
    method: 'post',
    data
  })
}
export function getStockList(query) {
  return request({
    url: 'get-stocks',
    method: 'get',
    params: query
  })
}
//
