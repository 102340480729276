import { _ } from "vue-underscore";
/**
 * Validation Rules for Name
 * @param {*} fieldName 
 * @param {*} extraRule 
 * @returns 
 */
export function nameRule(fieldName, extraRule = {}) {
    let rules = [{
        message: genMessage(fieldName, 'required'),
        trigger: "blur",
        required: true,
    },
    {
        message: converToNormalCase(fieldName) + " should be minimum 2 character",
        trigger: "blur",
        min: 2,
    },
    ];
    return returnExtraRule(fieldName, extraRule, rules);
}
/**
 * Validation Rules for User Name
 * @param {*} fieldName 
 * @param {*} extraRule 
 * @returns 
 */
export function userNameRule(fieldName, extraRule = []) {
    let rules = [{
        message: genMessage(fieldName, 'required'),
        trigger: "blur",
        required: true,
    }];
    return returnExtraRule(fieldName, extraRule, rules);
}
/**
 * Validation Rules for Phone No.
 * @param {*} fieldName 
 * @param {*} extraRule 
 * @returns 
 */
export function phoneNoRule(fieldName, extraRule = []) {
    let rules = [
        {
            message: converToNormalCase(fieldName) + " should be  10 digit",
            trigger: "blur",
            min: "10",
            max: "15"
        },
        {
            message: converToNormalCase(fieldName) + " should be numeric",
            trigger: "blur",
            pattern: /^[0-9]*$/,
        }
    ];
    return returnExtraRule(fieldName, extraRule, rules);
}
/**
 * Validation Rules for Mobile No.
 * @param {*} fieldName 
 * @param {*} extraRule 
 * @returns 
 */
export function mobileNoRule(fieldName, extraRule = []) {
    let rules = [
        {
            message: converToNormalCase(fieldName) + " should be  10 digit",
            trigger: "blur",
            min: "10",
            max: "15"
        },
        {
            message: converToNormalCase(fieldName) + " should be numeric",
            trigger: "blur",
            pattern: /^\d*$/,
        }
    ];
    return returnExtraRule(fieldName, extraRule, rules);
}
/**
* validation Rule For Number
* @param {form Atribute Name} fieldName 
* @param {eg:required ,string} rule 
* @returns 
*/
export function numberRule(fieldName, extraRule = []) {
    let rules = [
        {
            message: converToNormalCase(fieldName) + " should be numeric",
            trigger: "blur",
            pattern: /^\d*$/,
        }
    ];
    return returnExtraRule(fieldName, extraRule, rules);
}
/**
* validation Rule For Number
* @param {form Atribute Name} fieldName 
* @param {eg:required ,string} rule 
* @returns 
*/
export function decimalRule(fieldName, extraRule = []) {
    let rules = [
        {
            message: converToNormalCase(fieldName) + " should be decimal",
            trigger: "blur",
            pattern: /^\d+(\.\d{1,2})?$/,
        }
    ];
    return returnExtraRule(fieldName, extraRule, rules);
}
/**
 * Validation Rules for Required fields.
 * @param {*} fieldName 
 * @param {*} extraRule 
 * @returns 
 */
export function requiredRule(fieldName, extraRule = []) {
    let rules = [{
        message: genMessage(fieldName, 'required'),
        trigger: "blur",
        required: true,
    }
    ];
    return returnExtraRule(fieldName, extraRule, rules);
}
/**
 * Validation Rules for email Rules.
 * @param {*} fieldName 
 * @param {*} extraRule 
 * @returns 
 */
export function emailRule(fieldName, extraRule = []) {
    let rules = [
        {
            message: converToNormalCase(fieldName) + " should be valid email",
            trigger: "blur",
            type: "email",
        },
    ];
    return returnExtraRule(fieldName, extraRule, rules);
}
/**
 * validation for Short Code
 * @param {*} fieldName 
 * @param {*} extraRule 
 * @returns 
 */
export function shortCodeRule(fieldName, extraRule = []) {
    let rules = [
        {
            message: converToNormalCase(fieldName) + " should be valid string",
            trigger: "blur",
            type: "string",
        },
        {
            message: converToNormalCase(fieldName) + " should be minimum 3 character",
            trigger: "blur",
            min: 2,
        },
    ];
    return returnExtraRule(fieldName, extraRule, rules);
}
/**
 * validation for Register Number
 * @param {*} fieldName 
 * @param {*} extraRule 
 * @returns 
 */
export function registrationNumberRule(fieldName, extraRule = []) {
    let rules = [
        {
            message: genMessage(fieldName, 'required'),
            trigger: "blur",
            required: true,
        },
        {
            message: converToNormalCase(fieldName) + " should be valid string",
            trigger: "blur",
            type: "string",
        },
        {
            message: converToNormalCase(fieldName) + " should be minimum 3 character",
            trigger: "blur",
            min: 3,
        },
    ];
    return returnExtraRule(fieldName, extraRule, rules);
}
/**
 * validation for Pincode Validation
 * @param {*} fieldName 
 * @param {*} extraRule 
 * @returns 
 */
export function pincodeRule(fieldName, extraRule = []) {
    let rules = [
        {
            message: genMessage(fieldName, 'required'),
            trigger: "blur",
            required: true,
        },
    ];
    return returnExtraRule(fieldName, extraRule, rules);
}
/**
 * validation for URl Validation
 * @param {*} fieldName 
 * @param {*} extraRule 
 * @returns 
 */
export function urlRule(fieldName, extraRule = []) {
    let rules = [
        {
            message: converToNormalCase(fieldName) + " shoulb be in right formate",
            trigger: "blur",
            pattern: /^(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i
            ,
        },
    ];
    return returnExtraRule(fieldName, extraRule, rules);
}

/**
* 
* @param {form Atribute Name} fieldName 
* @param {eg:required ,string} rule 
* @returns 
*/
function genMessage(fieldName, rule) {
    return converToNormalCase(fieldName) + " should be " + rule;
}
/**
 * 
 * @param {Form  Filed Name Atribute Name} fieldName 
 * @param {eg : required,Type} extraRule 
 * @param {pre define Rules} rules 
 * @returns 
 */
function returnExtraRule(fieldName, extraRule, rules) {
    if (extraRule.length) {
        if (_.contains(extraRule, 'required')) {
            _.without(extraRule, 'required');
        }
    }
    _.every(extraRule, function (value_rule) {
        let rule = {
            message: genMessage(fieldName, value_rule),
            trigger: "blur",
        }
        if (value_rule == "required") {
            rule[value_rule] = true;
        } else {
            rule['type'] = value_rule;
        }
        rules.push(rule);
    })
    return rules;
}
/**
 * 
 * @param {camelCase} text 
 * @returns Normal text 
 */
function converToNormalCase(text) {
    // const result = text.replace(/([A-Z])/g, " $1");
    // const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    let result = text.split("_");
    result.forEach((w, index) => {
        result[index] = w.charAt(0).toUpperCase().concat(w.slice(1, w.length))
    });
    const finalResult = result.join(" ");
    return finalResult;
}

// /**
//  * 
//  * @param {Normal Text} string 
//  * @returns 
//  */
// function toCapitalize(string) { return string.charAt(0).toUpperCase() + string.slice(1); }