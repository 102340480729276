<template>
  <div class="menu_wrapper_container box-shadow" v-loading="loading">
      <div class="trading-client">
        <el-form
          :model="formData"
          :rules="formRules"
          ref="refForm"
          label-position="top"
          :status-icon="true"
        >
          <!-- Personal Details -->
          <el-card class="user_add_wrapper">
            <div>
              <el-row :gutter="20">
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                  <h3>Personal Details:</h3>
                </el-col>
              </el-row>

              <el-row :gutter="20" class="trading-client">
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.name"
                    label="First Name"
                    prop="name"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      v-model="formData.name"
                      autocomplete="off"
                    ></el-input>
                    <!-- <input v-model="formData.id" autocomplete="off" hidden /> -->
                    <input v-model="formData.id" autocomplete="off" hidden />

                    
                  </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.last_name"
                    label="Last Name"
                    prop="last_name"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      v-model="formData.last_name"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.username"
                    label="Username"
                    prop="username"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      v-model="formData.username"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.password"
                    label="Password"
                    prop="password"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                    v-model="formData.password"
                    type="password"
                    autocomplete="off"
                    show-password
                  />

                  </el-form-item>
                </el-col>
                <el-col v-if="getRoleSlug === 'admin' || getRoleSlug === 'superadmin'" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.broker_transaction_password"
                    label="Broker Transaction Password"
                    prop="broker_transaction_password"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                    v-model="formData.broker_transaction_password"
                    type="password"
                    autocomplete="off"
                    show-password
                  />
                  </el-form-item>
                </el-col>
                <el-col
                  v-if="getRoleSlug === 'superadmin'"
                  :xs="24"
                  :sm="12"
                  :md="12"
                  :lg="12"
                  :xl="12"
                >
                  <el-form-item
                    :error="formErrors.admin"
                    label="Admin"
                    prop="admin"
                    :label-width="formLabelWidth"
                  >
                    <el-select v-model="formData.admin" @change="handleAdminAllowedScript" placeholder="Select">
                      <el-option
                        v-for="item in listAdmins"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <!-- <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.credit_limit"
                    label="Credit Limit"
                    prop="credit_limit"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      v-model="formData.credit_limit"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                </el-col> -->
                <!-- <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.maximum_no_trade_allowed"
                    label="Maximum No. of Trade Allowed"
                    prop="maximum_no_trade_allowed"
                    :label-width="formLabelWidth"
                  >
                    <el-input
                      v-model="formData.maximum_no_trade_allowed"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                </el-col> -->
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.type"
                    label="Type"
                    prop="type"
                    :label-width="formLabelWidth"
                  >
                    <el-select
                      v-model="formData.type"
                      clearable
                      filterable
                      placeholder="Select"
                    >
                      <el-option label="Broker" value="broker"> </el-option>
                      <!-- <el-option label="Office Staff" value="office_staff">
                      </el-option> -->
                    </el-select>
                  </el-form-item>
                </el-col>

                
                
              </el-row>
            </div>
          </el-card>
          <br>

          <!-- Config  -->
          <el-card class="user_add_wrapper">
            <div>
              <el-row :gutter="20" class="trading-client">
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                  <h3>Config:</h3>
                </el-col>
              </el-row>
                <el-row :gutter="20" class="trading-client">
                  <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6">
                    <el-form-item
                      :error="formErrors.config_profit_loss_share_to_broker"
                      label=""
                      prop="config_profit_loss_share_to_broker"
                      :label-width="formLabelWidth"
                    >
                      <el-checkbox v-model="config.config_profit_loss_share_to_broker"
                        >Profit / loss Share to broker</el-checkbox
                      >
                    </el-form-item>
                  <el-form-item v-if="config.config_profit_loss_share_to_broker"
                      :error="formErrors.config_profit_loss_share_to_broker_value"
                      label=""
                      prop="config_profit_loss_share_to_broker_value"
                      :label-width="formLabelWidth"
                      >
                      <el-input
                        v-model="config.config_profit_loss_share_to_broker_value"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                </el-col>
               
                <el-col :xs="8" :sm="6" :md="6" :lg="6" :xl="6" v-if="masterSetting.allow_to_broker_to_client_create">
                  <el-form-item
                    :error="formErrors.config_create_client"
                    label=""
                    prop="config_create_client"
                    :label-width="formLabelWidth"
                  >
                    <el-checkbox v-model="config.config_create_client"
                      >Create Client</el-checkbox
                    >
                  </el-form-item>
                  <el-form-item v-if="config.config_create_client"
                    :error="formErrors.allowedclient"
                    label=""
                    prop="Allowed client"
                    :label-width="formLabelWidth"
                    placeholder="no. of client create"
                    >
                    <el-input
                      v-model="config.allowedclient"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item
                    :error="formErrors.config_edit_client"
                    label=""
                    prop="config_edit_client"
                    :label-width="formLabelWidth"
                  >
                    <el-checkbox v-model="config.config_edit_client"
                      >Edit Client</el-checkbox
                    >
                  </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="6" :md="6" :lg="6" :xl="6">
                  <el-form-item
                    :error="formErrors.allow_broker_fund_action"
                    label=""
                    prop="allow_broker_fund_action"
                    :label-width="formLabelWidth"
                  >
                    <el-checkbox v-model="config.allow_broker_fund_action"
                      >Allow Broker Fund Action</el-checkbox
                    >
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="trading-client">
                <el-col :xs="12" :sm="5" :md="5" :lg="5" :xl="5">
                  <el-form-item
                    :error="formErrors.allow_create_duplicate"
                    label=""
                    prop="allow_create_duplicate"
                    :label-width="formLabelWidth"
                  >
                    <el-checkbox v-model="config.allow_create_duplicate"
                      >Allow Create Duplicate</el-checkbox
                    >
                  </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="5" :md="5" :lg="5" :xl="5">
                  <el-form-item
                    :error="formErrors.allow_broker_trade_for_client"
                    label=""
                    prop="allow_broker_trade_for_client"
                    :label-width="formLabelWidth"
                  >
                    <el-checkbox v-model="config.allow_broker_trade_for_client"
                      >Allow Trade for client</el-checkbox
                    >
                  </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="5" :md="5" :lg="5" :xl="5">
                  <el-form-item
                    :error="formErrors.allow_to_notification_view"
                    label=""
                    prop="allow_to_notification_view"
                    :label-width="formLabelWidth"
                  >
                    <el-checkbox v-model="config.allow_to_notification_view"
                      >Allow To Notification View</el-checkbox
                    >
                  </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="5" :md="5" :lg="5" :xl="5">
                  <el-form-item
                    :error="formErrors.allow_to_history_view"
                    label=""
                    prop="allow_to_history_view"
                    :label-width="formLabelWidth"
                  >
                    <el-checkbox v-model="config.allow_to_history_view"
                      >Allow To History View</el-checkbox
                    >
                  </el-form-item>
                </el-col>
                <el-col :xs="12" :sm="4" :md="4" :lg="4" :xl="4">
                  <el-form-item
                    :error="formErrors.account_status"
                    label=""
                    prop="account_status"
                    :label-width="formLabelWidth"
                  >
                    <el-checkbox v-model="config.account_status"
                      >Account Status</el-checkbox
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-card>
          <br>

          <!-- config brokerage -->
          <el-card class="user_add_wrapper">
            <div>
              <el-row :gutter="20" class="trading-client">
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                  <h3>Brokerage Config:</h3>
                </el-col>
              </el-row>

              <el-row :gutter="20" class="trading-client">
                <el-col :xs="8" :sm="8" :md="8" :lg="8" :xl="8">
                  <el-form-item
                    :error="formErrors.config_profit_loss_share_to_broker"
                    label=""
                    prop="config_brokerage_share_to_broker"
                    :label-width="formLabelWidth"
                  >
                    <el-checkbox v-model="config.config_brokerage_share_to_broker" :disabled="config.config_brokerage_charge_to_broker ? '' : disabled"
                      >Brokerage Share to broker</el-checkbox
                    >
                  </el-form-item>
                <el-form-item v-if="config.config_brokerage_share_to_broker"
                    :error="formErrors.config_brokerage_share_to_broker_value"
                    label=""
                    prop="config_brokerage_share_to_broker_value"
                    :label-width="formLabelWidth"
                    >
                    <el-input
                      v-model="config.config_brokerage_share_to_broker_value"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="20" class="trading-client">
                <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                  <el-form-item
                    :error="formErrors.config_profit_loss_charge_to_broker"
                    label=""
                    prop="config_brokerage_charge_to_broker"
                    :label-width="formLabelWidth"
                  >
                    <el-checkbox v-model="config.config_brokerage_charge_to_broker"
                    :disabled="config.config_brokerage_share_to_broker ? '' : disabled"
                      >Brokerage Charge to broker</el-checkbox
                    >
                  </el-form-item>
                </el-col>
              </el-row>
              <el-row :gutter="20" class="trading-client" v-if="config.config_brokerage_charge_to_broker">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item 
                      :error="formErrors.equity_brokerage_type"
                      label="Equity Brokerage Type"
                      prop="equity_brokerage_type"
                      :label-width="formLabelWidth"
                    >
                      <el-select
                        v-model="config.equity_brokerage_type"
                        clearable
                        filterable
                        placeholder="Select"
                        @blur="handleBlurNSEequity_brokerage_type"
                      >
                        <el-option label="Per Crore Basics" value="per_core_basics">
                        </el-option>
                        <!-- <el-option label="Per Lot Basics" value="per_lot_basics">
                        </el-option> -->
                        <!-- <el-option label="Crud Oil" value="003"> </el-option> -->
                      </el-select>
                      <small v-if="error_equity_brokerage_type" style="color:red;">Required Equity Brokerage Type</small>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item
                      :error="formErrors.equity_brokerage"
                      label="Equity Brokerage"
                      prop="equity_brokerage"
                      :label-width="formLabelWidth"
                    >
                      <el-input @blur="handleBlurNSEequity_brokerage"
                        v-model="config.equity_brokerage"
                        autocomplete="off"
                      ></el-input>
                      <small v-if="error_equity_brokerage" style="color:red;">Required Equity Brokerage</small>
                    </el-form-item>
                  </el-col>
              </el-row>
              <el-row :gutter="20" class="trading-client" v-if="config.config_brokerage_charge_to_broker">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item 
                      :error="formErrors.opt_brokerage_type"
                      label="OPT Brokerage Type"
                      prop="opt_brokerage_type"
                      :label-width="formLabelWidth"
                    >
                      <el-select
                        v-model="config.opt_brokerage_type"
                        clearable
                        filterable
                        placeholder="Select"
                      >
                        <el-option label="Per Crore Basis" value="per_core_basis"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item 
                      :error="formErrors.opt_brokerage_per_crore"
                      label="OPT brokerage per crore"
                      prop="opt_brokerage_per_crore"
                      :label-width="formLabelWidth"
                    >
                      <el-input
                        v-model="config.opt_brokerage_per_crore"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                  </el-col>
              </el-row>
               <el-row :gutter="20" class="trading-client" v-if="config.config_brokerage_charge_to_broker">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item 
                      :error="formErrors.crypto_brokerage_type"
                      label="Crypto Brokerage Type"
                      prop="crypto_brokerage_type"
                      :label-width="formLabelWidth"
                    >
                      <el-select
                        v-model="config.crypto_brokerage_type"
                        clearable
                        filterable
                        placeholder="Select"
                      >
                        <el-option label="Per Crore Basis" value="per_core_basis"></el-option>
                        <el-option label="Per Qty Wise" value="per_qty_wise"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="config.crypto_brokerage_type == 'per_qty_wise'">
                    <el-form-item 
                      :error="formErrors.crypto_brokerage_per_qty"
                      label="Crypto brokerage per qty"
                      prop="crypto_brokerage_per_qty"
                      :label-width="formLabelWidth"
                    >
                      <el-input
                        v-model="config.crypto_brokerage_per_qty"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="config.crypto_brokerage_type == 'per_core_basis'">
                    <el-form-item 
                      :error="formErrors.crypto_brokerage_per_crore"
                      label="Crypto brokerage per crore"
                      prop="crypto_brokerage_per_crore"
                      :label-width="formLabelWidth"
                    >
                      <el-input
                        v-model="config.crypto_brokerage_per_crore"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                  </el-col>
              </el-row>
              <el-row :gutter="20" class="trading-client" v-if="config.config_brokerage_charge_to_broker">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item 
                      :error="formErrors.forex_brokerage_type"
                      label="Forex Brokerage Type"
                      prop="forex_brokerage_type"
                      :label-width="formLabelWidth"
                    >
                      <el-select
                        v-model="config.forex_brokerage_type"
                        clearable
                        filterable
                        placeholder="Select"
                      >
                        <el-option label="Per Crore Basis" value="per_core_basis"></el-option>
                        <el-option label="Per Qty Wise" value="per_qty_wise"></el-option>
                      </el-select>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="config.forex_brokerage_type == 'per_qty_wise'">
                    <el-form-item 
                      :error="formErrors.forex_brokerage_per_qty"
                      label="Forex brokerage per qty"
                      prop="forex_brokerage_per_qty"
                      :label-width="formLabelWidth"
                    >
                      <el-input
                        v-model="config.forex_brokerage_per_qty"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12" v-if="config.forex_brokerage_type == 'per_core_basis'">
                    <el-form-item 
                      :error="formErrors.forex_brokerage_per_crore"
                      label="Forex brokerage per crore"
                      prop="forex_brokerage_per_crore"
                      :label-width="formLabelWidth"
                    >
                      <el-input
                        v-model="config.forex_brokerage_per_crore"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                  </el-col>
              </el-row>

              <el-row :gutter="20" class="trading-client" v-if="config.config_brokerage_charge_to_broker">
                  <el-col :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item
                    :error="formErrors.mcx_brokerage_type"
                    label="Mcx Brokerage Type"
                    prop="mcx_brokerage_type"
                    :label-width="formLabelWidth"
                  >
                    <el-select
                      v-model="config.mcx_brokerage_type"
                      clearable
                      filterable
                      placeholder="Select"
                      @blur="handleBlurMCXmcx_brokerage_type"
                    >
                      <el-option label="Per Crore Basis" value="per_core_basis"></el-option>
                      <el-option label="Per Lot Basis" value="per_lot_basis"></el-option>
                    </el-select>
                    <small v-if="error_mcx_brokerage_type" style="color:red;">Required Mcx Brokerage Type</small>
                    </el-form-item>
                  </el-col>
                  <el-col v-if="config.mcx_brokerage_type == 'per_core_basis'" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.mcx_brokerage_per_crore"
                    label="MCX brokerage per crore"
                    prop="mcx_brokerage_per_crore"
                    :label-width="formLabelWidth"
                  >
                  <el-input @blur="handleBlurMCXmcx_brokerage_per_crore"
                    v-model="config.mcx_brokerage_per_crore"
                    autocomplete="off"
                  ></el-input>
                  <small v-if="error_mcx_brokerage_per_crore" style="color:red;">Required MCX brokerage per crore</small>

                </el-form-item>
              </el-col>
              <!-- {{mcx_brokerage_lot_wise}} -->
                <div v-if="config.mcx_brokerage_type == 'per_lot_basis'">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="left">
                    <h5>MCX Brokerage Lot wise:</h5>
                  </el-col>
                  <el-col v-for="(mcx, index) in config.mcx_brokerage_lot_wise" :key="index" :xs="24" :sm="12" :md="12" :lg="12" :xl="12">
                    <el-form-item
                      :label="mcx.name"
                    >
                      <el-input
                        v-model="config.mcx_brokerage_lot_wise[index].value"
                        autocomplete="off"
                      ></el-input>
                    </el-form-item>
                  </el-col>
                </div>
              </el-row>
                



              
            </div>
          </el-card>

          
          <br>
          

          <!-- Security -->
          <el-card class="user_add_wrapper" v-if="getRoleSlug === 'admin' || getRoleSlug === 'broker'">
            <div>
              <el-row :gutter="20" class="trading-client">
                <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="12" class="left">
                  <h3>Security:</h3>
                </el-col>
              </el-row>

              <el-row :gutter="20" class="trading-client">
                <el-col v-if="getRoleSlug === 'admin' || getRoleSlug === 'broker'" :xs="12" :sm="12" :md="12" :lg="12" :xl="12">
                  <el-form-item
                    :error="formErrors.transaction_password"
                    label="Transaction Password"
                    prop="transaction_password"
                    :label-width="formLabelWidth"
                  >
                    
                    <el-input
                    v-model="formData.transaction_password"
                    type="password"
                    autocomplete="off"
                    show-password
                  />
                  </el-form-item>
                </el-col>
                
            
              <!-- <el-col
                  :xs="24"
                  :sm="12"
                  :md="12"
                  :lg="12"
                  :xl="12"
                >
                  <el-form-item
                    label="Block Script"
                  >
                 <br>
                 <el-button type="primary" @click="addScript">+</el-button>
                  </el-form-item>
                </el-col> -->
              </el-row>
            </div>
          </el-card>

       
            <br>

          <el-row :gutter="20">
            <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="right">
            <el-button @click="handelCancel()">Cancel</el-button>
              &nbsp;
              <el-button type="info" @click="sendChildFormData()">Save</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>

     <ScriptAddEditDialog
      :dialog-visible.sync="dialogVisibleScriptAddEdit"
      :dialog-type="dialogTypeScriptAddEdit"
      :form-data="formData"
      :mcxStockList="mcxStockListDataSet"
      :nseStockList="nseStockListDataSet"
      :othStockList="othStockListDataSet"
      :form-errors.sync="formErrors"
      @getChildFormData="handleDuplicateAccount($event)"
      @childClose="handleDuplicateAccountClose()"
    />
  </div>
</template>




<script>
import { requiredRule } from "@/rules/all-rules";
import getRoleSlug from '@/store/app.js';
// import { edit } from "@/api/trading-broker";
import { store, edit, update } from "@/api/trading-broker";
import { mapGetters } from "vuex";
import { fetchAdminAllowedStockList } from "@/api/market-watch";
import ScriptAddEditDialog from "@/views/users/components/ScriptAddEditDialog.vue";
import { fetchAllAdminList } from "@/api/super-admin/admin";
import { fetch , fetchMasterSettings} from "@/api/settings";

export default {
  name: "AddEditDialog",
  components: {
    ScriptAddEditDialog
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formRules: {},
      masterSetting: {},
      formLabelWidth: "120px",
      formData: {
        id: "",
        mcx_script: [],
        nse_script: [],
        oth_script: []
      },
      listScripts: {},
      mcxStockList: {},
      mcxExposureStockList: {},
      nseStockList: {},
      othStockList: {},
      config: {},
      mcx: {},
      equity: {},
      opt: {},
      dialogVisibleScriptAddEdit: false,
      dialogTypeScriptAddEdit: "edit",
      mcxStockListDataSet: {},
      nseStockListDataSet: {},
      othStockListDataSet: {},
      listAdmins: {},
    };
  },
  computed: {
    //  ...mapGetters(["userInfo"]),
     ...mapGetters(["getRoleSlug"]),
    dialogTitle() {
      return this.dialogType === "create"
        ? "Add Pending Order"
        : "Edit Pending Order";
    },
  },
  created() {
    this.id = this.$route.params.id;
    console.log(this.$route.params.id);
    this.fetchData(this.$route.params.id);
    this.formRules.name = requiredRule("name");
    this.formRules.username = requiredRule("username");
    this.formRules.transaction_password = requiredRule("transaction_password");
    this.formRules.admin = requiredRule("admin");
    //this.formRules.password = requiredRule("password");
    if(getRoleSlug.state.roleSlug == 'superadmin') {
      this.getAdminList()
    }else{
      this.getSettingsDetails()
    }
    
  },
  methods: {
    addScript() {
        this.dialogVisibleScriptAddEdit = true;
        this.dialogTypeScriptAddEdit = "edit";
        this.mcxStockListDataSet = this.mcxStockList;
        this.nseStockListDataSet = this.nseStockList;
        this.othStockListDataSet = this.othStockList;
    },
    handleDuplicateAccountClose() {
      this.dialogVisibleScriptAddEdit = false;
      this.delectval = null;
    },
    handelCancel() {
      return this.$router.push("/"+ getRoleSlug.state.roleSlug +"/users");
    },
    sendChildFormData() {
      this.loading = true;
      this.$refs.refForm.validate((valid) => {
        if (valid) {
           let mcxObj = {
            ...this.mcx,
            mcxBrokerageLotWise: this.filterBrokerageLot(this.mcxStockList),
            mcxExposureLotWise: this.filterExposureLot(this.mcxExposureStockList)
           }
           this.formData = Object.assign(this.formData, {
              config: this.config,
              mcx: mcxObj,
              equity: this.equity,
              opt: this.opt,
              status: true,
            });

          console.log(this.formData);

          if (this.formData.id != "") {
           update(this.formData)
            .then((response) => {
              if (response.data.success === true) {
                this.flashSuccess(response.data.message);
                this.loading = false;
                // this.$router.push("/"+ getRoleSlug.state.roleSlug +"/users");
                if(getRoleSlug.state.roleSlug == 'superadmin') {
                  return this.$router.push("/super-admin/users");
                } else {
                  return this.$router.push("/" + getRoleSlug.state.roleSlug + "/users");
                }
                // location.reload()
              } else {
                this.formErrors = this.validationErrors(response.data.message);
              }
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
            });
          } else {
            console.log(this.formData);
           store(this.formData)
            .then((response) => {
              console.log(response.data)
              if (response.data.success === true) {
                this.flashSuccess(response.data.message);
                this.loading = false;
                // this.$router.push("/"+ getRoleSlug.state.roleSlug +"/users");
                 if(getRoleSlug.state.roleSlug == 'superadmin') {
                    return this.$router.push("/super-admin/users");
                  } else {
                    return this.$router.push("/" + getRoleSlug.state.roleSlug + "/users");
                  }
                // location.reload()
              } else {
                this.flashError(response.data.message.username);
                this.formErrors = this.validationErrors(response.data.message);
              }
              this.loading = false;
            })
            .catch((err) => {
              console.log(err);
            });
          }
        }
        this.loading = false;
      });
    },
    filterBrokerageLot(data) {
      let dataPost = []
      for(let i=0; i < data.length; i++) {
        if(data[i].value) {
          dataPost.push({
            stock_id: data[i].stock_id,
            value: data[i].value,
          })
        }
      }
      return dataPost;
    },
    filterExposureLot(data) {
      let dataPost = []
      for(let i=0; i < data.length; i++) {
        if(data[i].value) {
          dataPost.push({
            stock_id: data[i].stock_id,
            value: data[i].value,
          })
        }
      }
      return dataPost;
    },
    ///////////////////
    //// EDIT
    ///////////////////
    fetchData(id){
      if(id) {
        this.fetchEditData(id)
      }
    },
    // fetch by id
    fetchEditData(id) {
      // this.loading = true;
      edit(id)
        .then((response) => {
          this.formData = response.data.data;
          if (response.data.success === true) {
            this.formData = response.data.data;
            this.config = response.data.data.config;
            this.equity = response.data.data.equity;
            this.mcx = response.data.data.mcx;
            this.opt = response.data.data.opt;
            // this.loading = false;
          } else {
            this.$message.warning(response.data.message);
          }
          this.getScriptList()
          
        })
        .catch((err) => {
          console.log(err);
        });
      this.dialogFormVisible = true;
    },
    getScriptList() {
      let dataPost = {
        adminId : this.formData ? this.formData.admin : null
      }
      fetchAdminAllowedStockList(dataPost).then((response) => {
        this.listScripts  = response.data.data.all;
        let tempt = [], tempt1 = [];
        let temptExposureStockList = [];
        // this.mcxStockList  = response.data.data.mcxStockList;
        console.log("fserfeuwyiuwery    ", response.data.data)
        for(let i = 0; i < response.data.data.mcxStockList.length; i++) {
          tempt.push({
            name: response.data.data.mcxStockList[i].name,
            id: response.data.data.mcxStockList[i].id,
            stock_id: response.data.data.mcxStockList[i].stock_id,
            value: 200
          })
        }
        for(let i = 0; i < response.data.data.mcxStockUniqueList.length; i++) {
          tempt1.push({
            name: response.data.data.mcxStockUniqueList[i].name,
            value: 1000
          })
        }
        for(let i = 0; i < response.data.data.mcxStockList.length; i++) {
          temptExposureStockList.push({
            name: response.data.data.mcxStockList[i].name,
            id: response.data.data.mcxStockList[i].id,
            stock_id: response.data.data.mcxStockList[i].stock_id,
            value: ''
          })
        }
        this.mcxStockList = tempt;
        this.mcxExposureStockList = temptExposureStockList;
        this.nseStockList  = response.data.data.nseStockList;
        this.othStockList  = response.data.data.othStockList;
        console.log("this.config.config_brokerage_charge_to_broker ",this.config.config_brokerage_charge_to_broker)
        if(!this.config.config_brokerage_charge_to_broker){
          this.config.mcx_brokerage_lot_wise = tempt1;
        }

        console.log(" this.config.mcx_brokerage_lot_wise ", this.config.mcx_brokerage_lot_wise)
        this.loading = false;
      });
    },
    getAdminList() {
      fetchAllAdminList().then((response) => {
        this.listAdmins  = response.data.data;
      });
    },
    handleAdminAllowedScript() {
      this.getScriptList();
      this.getMasterSettings();
    },
    // //////////////////
    // GET LIST DATA
    // //////////////////
    getSettingsDetails() {
      this.loading = true;
      fetch().then((response) => {
        this.masterSetting = response.data.data;
        // this.filters2(this.viewData.mcx_start_trading_at)
        this.loading = false;
      });
    },
    getMasterSettings() {
      this.loading = true;
      let params = {
        adminId : this.formData ? this.formData.admin : null
      };
      fetchMasterSettings(params).then((response) => {
        this.masterSetting = response.data.data;
        // this.filters2(this.viewData.mcx_start_trading_at)
        this.loading = false;
      });
    },


  },
};
</script>

