import request from '@/utils/request'

/*
@keyword : manager-api-hosts
*/
export function fetchList(query) {
  return request({
    url: '/admins',
    method: 'get',
    params: query
  })
}

export function fetchAllAdminList(query) {
  return request({
    url: '/adminall',
    method: 'get',
    params: query
  })
}

export function show(id) {
  return request({
    url: `admins/view/`,
    method: 'get',
    params: { id }
  })
}

export function store(data) {
  return request({
    url: '/admins/store',
    method: 'post',
    data
  })
}

export function edit(id) {
  return request({
    url: `admins/edit/`,
    method: 'get',
    params: { id }
  })
}


export function update(data) {
  return request({
    url: '/admins/update',
    method: 'post',
    data
  })
}


export function destroy(id) {
  return request({
    url: `admins/${id}`,
    method: 'delete'
  })
}


export function adminBrokers(id) {
  return request({
    url: `adminBrokers/`,
    method: 'get',
    params: { id }
  })
}

export function adminBrokerClients(id) {
  return request({
    url: `adminBrokerClients/`,
    method: 'get',
    params: { id }
  })
}

export function adminClients(id) {
  return request({
    url: `adminClients/`,
    method: 'get',
    params: { id }
  })
}

export function updateChange(data) {
  return request({
    url: '/admin/updatechange',
    method: 'post',
    data
  })
}
